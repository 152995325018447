import { MyRewardControllerService } from '@/__generated__/FrontApi';
import RewardImageRing from '@/assets/img/img_reward_01@2x.png';
import RewardImageBracelet from '@/assets/img/img_reward_02@2x.png';
import RewardImageTShirt from '@/assets/img/img_reward_t-shirt@2x.png';
import RewardImageUTBracelet from '@/assets/img/img_reward_utbracelet@2x.png';
import Button from '@/components/Button';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import FormTextTip from '@/components/Form/FormTextTip';
import LabelText from '@/components/Form/LabelText';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import AddressButton from '@/components/Input/AddressButton';
import InputRadio from '@/components/Input/InputRadio';
import InputText from '@/components/Input/InputText';
import { breakpoint } from '@/helpers/BreakpointHelper';
import {
  SOLICIT_CODE_BRACELET,
  SOLICIT_CODE_RING,
  SOLICIT_CODE_UTBRACELET,
  SOLICIT_CODE_T_SHIRT,
} from '@/helpers/SupportHelper';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import {
  FormButton,
  FormContainer,
  RightCol,
} from '@/page-blocks/auth/AuthCommon';
import { MyRewardVo } from '@/private-pages/mypage/reward-list';
import { usePopupStore } from '@/stores/PopupStore';
import { PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const RewardForm = styled.div``;
const RewardImageContainer = styled.div`
  text-align: center;
  margin-bottom: 48px;
  margin-top: 24px;
  ${breakpoint(640)} {
    margin-top: 0;
  }
`;
const AgreeCheckFlex = styled.div`
  margin: 16px 0 32px 0;
  label {
    width: 50%;
  }
`;

interface RewardFormData {
  zipCode: string;
  address: string;
  addressDetail: string;
  dpoAddressYn: 'Y' | 'N';
}

const RewardAddressForm: FC<PageProps> = observer(({ location }) => {
  const popupStore = usePopupStore();
  const { donorIdEnc, authKey } = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );
  // 복호화된 donorId
  const donorId = useMemo(() => donorIdEnc && atob(donorIdEnc), [donorIdEnc]);

  const [item, setItem] = useState<MyRewardVo>();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<RewardFormData>({
    defaultValues: {},
  });

  // 발송현황 기존 데이터 로드
  const loadItem = useCallback(async () => {
    try {
      const {
        data,
      } = await MyRewardControllerService.getDonationAddressInfoUsingGet({
        authKey,
        donorId,
      });
      // 넘어온 값 세팅
      setItem(data);
      reset(data);
    } catch (e) {
      if (e.response) {
        popupStore.show(
          e.response.data.resultMessage || `기존 정보를 불러오지 못했습니다.`,
        );
      }
    }
  }, [authKey, donorId, popupStore, reset]);

  useEffect(() => {
    if (!donorId || !authKey) {
      popupStore.show(`필수 정보가 넘어오지 않았습니다.`);
      return;
    }
    loadItem();
  }, [authKey, donorId, loadItem, popupStore]);

  const onSubmit: SubmitHandler<RewardFormData> = useCallback(
    async (formData) => {
      // dp 테이블 우편번호 저장 안되고 있던 오류로 인하여
      // 우편번호가 없는 경우, 재입력 받을 수 있도록 수정.
      if (!formData.zipCode) {
        popupStore.show(`주소 입력을 확인해 주세요.`);
        return false;
      }
      try {
        const {
          resultCode,
          resultMessage,
        } = await MyRewardControllerService.postMyAddressEditUsingPost({
          ...formData,
          authKey,
          donorId,
        });
        if (resultCode === `success`) {
          // 데이터 업데이트
          loadItem();
        }

        popupStore.show(resultMessage);
      } catch (e) {
        if (e.response) {
          popupStore.show(
            e.response.data.resultMessage || `저장중 오류가 발생했습니다`,
          );
        }
      }
    },
    [authKey, donorId, loadItem, popupStore],
  );

  return (
    <LayoutWithoutTitle
      location={location}
      title="발송 현황"
      hideGnb
      hideMargin
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <RewardForm className="form-container">
          <RewardImageContainer>
            {item?.solicitCode === SOLICIT_CODE_RING && (
              <img src={RewardImageRing} alt="리워드 이미지" />
            )}
            {item?.solicitCode === SOLICIT_CODE_BRACELET && (
              <img src={RewardImageBracelet} alt="리워드 이미지" />
            )}
            {item?.solicitCode === SOLICIT_CODE_UTBRACELET && (
              <img src={RewardImageUTBracelet} alt="리워드 이미지" />
            )}
            {item?.solicitCode === SOLICIT_CODE_T_SHIRT && (
              <img src={RewardImageTShirt} alt="리워드 이미지" />
            )}
          </RewardImageContainer>
          <FormContainer>
            <FormGroup>
              <p>
                <strong
                  css={`
                    font-size: 16px;
                    display: block;
                    margin-bottom: 4px;
                  `}
                >
                  <span className="ellipsis">{item?.donorName}</span> 후원자님,
                </strong>
                {item?.solicitCode === SOLICIT_CODE_RING && (
                  <span>유니세프 Promise 링을</span>
                )}
                {item?.solicitCode === SOLICIT_CODE_BRACELET && (
                  <span>UNICEF TEAM 팔찌를</span>
                )}
                {item?.solicitCode === SOLICIT_CODE_UTBRACELET && (
                  <span>UNICEF TEAM 팔찌를</span>
                )}
                {item?.solicitCode === SOLICIT_CODE_T_SHIRT && (
                  <span>유니세프 티셔츠를</span>
                )}
                {` `}
                수령하실 주소지를 입력해주세요.
              </p>
            </FormGroup>
            <FormGroup>
              <LabelText require>주소</LabelText>
              <Row>
                <Col desktop="auto">
                  <InputText name="zipCode" ref={register} readOnly />
                </Col>
                <RightCol desktop="none">
                  <AddressButton
                    onSelectAddress={(address) => {
                      setValue(`zipCode`, address.zonecode);
                      setValue(`address`, address.address);
                    }}
                    renderButton={(buttonProps) => (
                      <FormButton {...buttonProps}>주소검색</FormButton>
                    )}
                  />
                </RightCol>
              </Row>
              <Row>
                <InputText
                  name="address"
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  readOnly
                />
              </Row>
              {errors.address && (
                <ErrorMessage>{errors.address.message}</ErrorMessage>
              )}
              <Row>
                <InputText
                  name="addressDetail"
                  ref={register({
                    required: {
                      value: true,
                      message: `주소를 입력해주세요.`,
                    },
                  })}
                />
              </Row>
              {errors.addressDetail && (
                <ErrorMessage>{errors.addressDetail.message}</ErrorMessage>
              )}
              <Row>
                <FormTextTip>
                  주소지는{` `}
                  <span className="ellipsis">발송예정일 7일 전까지</span>
                  {` `}
                  수정 가능하며 이후에는 수정이 불가능합니다.
                </FormTextTip>
              </Row>
            </FormGroup>
            <FormGroup>
              <LabelText>
                <strong>입력하신 주소를 후원자 정보에 저장하시겠습니까?</strong>
              </LabelText>
              <AgreeCheckFlex>
                <InputRadio
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  label="예"
                  name="dpoAddressYn"
                  value="Y"
                />
                <InputRadio
                  ref={register({
                    required: {
                      value: true,
                      message: `필수 입력입니다`,
                    },
                  })}
                  label="아니오"
                  name="dpoAddressYn"
                  value="N"
                />
              </AgreeCheckFlex>
              {errors.dpoAddressYn && (
                <ErrorMessage>{errors.dpoAddressYn.message}</ErrorMessage>
              )}
              <FormTextTip>
                '예'를 선택하시면 입력하신 주소를 후원자 정보에 저장합니다.
              </FormTextTip>
              <FormTextTip>
                '아니오'를 선택하시면 배송 완료 후 파기합니다.
              </FormTextTip>
            </FormGroup>
            <Button full type="submit">
              입력완료
            </Button>
          </FormContainer>
        </RewardForm>
      </form>
    </LayoutWithoutTitle>
  );
});

export default RewardAddressForm;
